// import {
//   getFirestore,
//   doc,
//   collection,
//   getDoc,
//   onSnapshot,
//   query,
// } from "@firebase/firestore";

import Axios from "axios";

export const state = {
  users: [],
  user: {},
  loading: false,
};

export const getters = {
  users(state) {
    return state.users;
  },
  user(state) {
    return state.user;
  },
};

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async getAll({ commit }) {
    commit("SET_LOADING", true);

    await Axios.get("https://server.sufoservices.com/admin/customers", {
      headers: {
        Authorization: `Bearer NzM.MEtrYoYUSM09ePfa2jnvwZNGZ_7uQLPlPhRo0PaXh12i-QAiEg3lEvLouQZA`,
      },
    }).then((res) => {
      const users = res.data.data;
      commit("SET_USERS", users);
      commit("SET_LOADING", false);
    });
  },
  async get({ commit }, id) {
    commit("SET_LOADING", true);

    const user = await Axios.get(
      `https://server.sufoservices.com/admin/customers/${id}`,
      {
        headers: {
          Authorization: `Bearer NzM.MEtrYoYUSM09ePfa2jnvwZNGZ_7uQLPlPhRo0PaXh12i-QAiEg3lEvLouQZA`,
        },
      }
    ).then((res) => {
      return res.data.user;
    });

    commit("SET_USER", user);
    commit("SET_LOADING", false);
    return user;
  },
};
