import { createStore } from "vuex";
import * as auth from "@/store/auth";
import * as products from "@/store/products";
import * as orders from "@/store/orders";
import * as users from "@/store/users";
import * as conversations from "@/store/conversations";
import * as users_permissions from "@/store/permissions/users";
import * as categories from "@/store/categories";
import * as home_settings from "@/store/settings/home-page";
import * as common_settings from "@/store/settings/common-settings";
import * as contacts_settings from "@/store/settings/contacts-settings";
import * as specs_settings from "@/store/settings/specs-settings";
import * as faqs from "@/store/faqs";

export default createStore({
  modules: {
    auth: {
      namespaced: true,
      ...auth,
    },
    users: {
      namespaced: true,
      ...users,
    },
    conversations: {
      namespaced: true,
      ...conversations,
    },
    users_permissions: {
      namespaced: true,
      ...users_permissions,
    },
    products: {
      namespaced: true,
      ...products,
    },
    orders: {
      namespaced: true,
      ...orders,
    },
    categories: {
      namespaced: true,
      ...categories,
    },
    common_settings: {
      namespaced: true,
      ...common_settings,
    },
    contacts_settings: {
      namespaced: true,
      ...contacts_settings,
    },
    specs_settings: {
      namespaced: true,
      ...specs_settings,
    },
    home_settings: {
      namespaced: true,
      ...home_settings,
    },
    faqs: {
      namespaced: true,
      ...faqs,
    },
  },
});
