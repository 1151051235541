import {
  getFirestore,
  doc,
  collection,
  getDoc,
  setDoc,
  deleteDoc,
  query,
  onSnapshot,
  addDoc,
} from "@firebase/firestore";

export const state = {
  faqs: [],
  product: {},
  loading: false,
};

export const getters = {
  faqs(state) {
    return state.faqs;
  },
  faq(state) {
    return state.faq;
  },
};

export const mutations = {
  SET_FAQS(state, faqs) {
    state.faqs = faqs;
  },
  SET_FAQ(state, faq) {
    state.faq = faq;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async getAll({ commit }) {
    commit("SET_LOADING", true);
    const q = query(collection(getFirestore(), "sav-system", "faqs", "data"));
    onSnapshot(q, (querySnapshot) => {
      const faqs = querySnapshot.docs.map((faq) => ({
        ...faq.data(),
        id: faq.id,
      }));
      commit("SET_FAQS", faqs);
      commit("SET_LOADING", false);
    });
  },
  async get({ commit }, id) {
    commit("SET_LOADING", true);
    const faq = await getDoc(
      doc(getFirestore(), "sav-system", "faqs", "data", id)
    );
    commit("SET_FAQ", { ...faq.data(), id: faq.id });
    commit("SET_LOADING", false);
    return { ...faq.data(), id: faq.id };
  },
  async delete({ commit }, id) {
    commit("SET_LOADING", true);
    await deleteDoc(doc(getFirestore(), "sav-system", "faqs", "data", id));
    commit("SET_LOADING", false);
  },
  async create({ commit }, faq) {
    commit("SET_LOADING", true);
    await addDoc(collection(getFirestore(), "sav-system", "faqs", "data"), faq);
    commit("SET_LOADING", false);
  },
  async update({ commit }, faq) {
    commit("SET_LOADING", true);

    await setDoc(
      doc(getFirestore(), "sav-system", "faqs", "data", faq.id),
      faq
    );
    commit("SET_LOADING", false);
  },
};
