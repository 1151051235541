<template>
  <layout-default>
    <router-view />
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/LayoutDefault";
import store from "./store";
import { getAuth, onAuthStateChanged } from "@firebase/auth";

export default {
  name: "App",
  components: {
    LayoutDefault,
  },
  mounted() {
    onAuthStateChanged(getAuth(), (user) => {
      store.dispatch("auth/fetchUser", user);
    });
  },
};
</script>
