<template>
  <div v-if="!isLoginPage">
    <app-mobile-menu></app-mobile-menu>
    <div class="flex">
      <app-side-menu></app-side-menu>
      <div class="content">
        <app-top-bar></app-top-bar>
        <slot />
      </div>
    </div>
  </div>
  <slot v-else />
</template>

<script>
import MobileMenuVue from "@/components/layouts/MobileMenu.vue";
import SideMenuVue from "@/components/layouts/SideMenu.vue";
import TopBarVue from "@/components/layouts/TopBar.vue";
export default {
  name: "app-layout",
  components: {
    "app-mobile-menu": MobileMenuVue,
    "app-side-menu": SideMenuVue,
    "app-top-bar": TopBarVue,
  },
  mounted() {
    console.log(this.$route.path);
  },
  computed: {
    isLoginPage() {
      return this.$route.path.includes("/login");
    },
  },
};
</script>

<style></style>
