export const MenuItems = [
  // {
  //   title: "Vue d'ensemble",
  //   icon: "home",
  //   to: "/",
  // },
  // {
  //   title: "Mes articles",
  //   icon: "shopping-bag",
  //   to: "/products",
  //   items: [
  //     {
  //       title: "Nouveau produit",
  //       icon: "plus-square",
  //       to: "/create-product",
  //       permission: "create_product",
  //     },
  //     {
  //       title: "Lister les produits",
  //       icon: "shopping-bag",
  //       to: "/list-product",
  //     },
  //   ],
  // },
  {
    title: "Commandes",
    icon: "shopping-cart",
    to: "/list-orders",
    items: [
      {
        title: "Nouvelle commande",
        icon: "plus-square",
        to: "/create-order",
        permission: "create_order",
      },
      {
        title: "Lister les commandes",
        icon: "shopping-bag",
        to: "/list-orders",
      },
    ],
  },
  // {
  //   title: "Utilisateurs",
  //   icon: "users",
  //   to: "/users",
  //   permission: "read_user",
  // },
  {
    title: "Messages",
    icon: "message-square",
    to: "/chats",
    permission: "manage_chats",
  },
  // {
  //   title: "Contacts",
  //   icon: "inbox",
  //   to: "/contacts",
  //   permission: "manage_contacts",
  // },
  // {
  //   title: "Configurations",
  //   icon: "settings",
  //   to: "/settings",
  //   permission: "update_setting",
  //   items: [
  //     {
  //       title: "Page d'acccueil",
  //       icon: "home",
  //       to: "/home-settings",
  //     },
  //     {
  //       title: "Générales",
  //       icon: "tool",
  //       to: "/general-settings",
  //     },
  //     {
  //       title: "Contacts",
  //       icon: "share-2",
  //       to: "/contacts-settings",
  //     },
  //     {
  //       title: "Modes de paiement",
  //       icon: "credit-card",
  //       to: "/payment-settings",
  //     },
  //     {
  //       title: "Spécificités",
  //       icon: "paperclip",
  //       to: "/specs-settings",
  //     },
  //   ],
  // },
  // {
  //   title: "FAQ's",
  //   icon: "book-open",
  //   to: "/faqs",
  //   permission: "update_setting",
  // },
];

export default {
  data() {
    return {
      menuItems: MenuItems,
    };
  },
};
