<template>
  <div class="top-bar">
    <app-breadcrumb></app-breadcrumb>
    <app-search-section
      :products="products"
      :users="users"
    ></app-search-section>
    <app-notifications-section></app-notifications-section>
    <app-account-menu></app-account-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountMenuVue from "./top-bar/AccountMenu.vue";
import BreadcrumbVue from "./top-bar/BreadcrumbSection.vue";
import NotificationsSectionVue from "./top-bar/NotificationsSection.vue";
import SearchSectionVue from "./top-bar/SearchSection.vue";

export default {
  name: "top-bar",
  components: {
    "app-breadcrumb": BreadcrumbVue,
    "app-search-section": SearchSectionVue,
    "app-notifications-section": NotificationsSectionVue,
    "app-account-menu": AccountMenuVue,
  },
  computed: {
    ...mapGetters({
      products: "products/products",
      users: "users/users",
      current_user: "auth/user",
    }),
  },
};
</script>

<style></style>
