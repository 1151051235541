<template>
  <!-- BEGIN: Notifications -->
  <div class="intro-x dropdown mr-auto sm:mr-6">
    <div
      class="dropdown-toggle notification notification--bullet cursor-pointer"
      role="button"
      aria-expanded="false"
    >
      <i data-feather="bell" class="notification__icon dark:text-gray-300"></i>
    </div>
    <div class="notification-content pt-2 dropdown-menu">
      <div
        class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
      >
        <div class="notification-content__title">Notifications</div>
        <div class="cursor-pointer relative flex items-center mt-5">
          <div class="w-12 h-12 flex-none image-fit mr-1">
            <img
              alt="Rubick Tailwind HTML Admin Template"
              class="rounded-full"
              src="/assets/images/profile-10.jpg"
            />
            <div
              class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
            ></div>
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a href="javascript:;" class="font-medium truncate mr-5"
                >Russell Crowe</a
              >
              <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                01:10 PM
              </div>
            </div>
            <div class="w-full truncate text-gray-600 mt-0.5">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 20
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Notifications -->
</template>

<script>
export default {};
</script>

<style></style>
