<template>
  <!-- BEGIN: Side Menu -->
  <nav class="side-nav">
    <router-link to="/" class="intro-x flex items-center pl-5 pt-4">
      <img
        alt="Rubick Tailwind HTML Admin Template"
        class="w-6"
        src="/assets/images/logo.svg"
      />
      <span class="hidden xl:block text-white text-lg ml-3">
        SAV & <span class="font-medium">Services</span>
      </span>
    </router-link>
    <div class="side-nav__devider my-6"></div>
    <ul>
      <li
        v-for="(item, index) of menuItems"
        :key="index"
        v-show="isActive(item)"
      >
        <a v-if="item.items?.length" href="javascript:;" class="side-menu">
          <div class="side-menu__icon"><i :data-feather="item.icon"></i></div>
          <div class="side-menu__title">
            {{ item.title }}
            <div class="side-menu__sub-icon">
              <i data-feather="chevron-down"></i>
            </div>
          </div>
        </a>
        <router-link
          v-else
          :to="item.to"
          class="side-menu"
          active-class="side-menu--active"
        >
          <div class="side-menu__icon">
            <i :data-feather="item.icon"></i>
          </div>
          <div class="side-menu__title">{{ item.title }}</div>
        </router-link>
        <ul v-if="item.items?.length" style="display: none">
          <li
            v-for="sub_item of item.items"
            :key="sub_item.to"
            v-show="isActive(sub_item)"
          >
            <router-link
              :to="sub_item.to"
              class="side-menu"
              active-class="side-menu--active"
            >
              <div class="side-menu__icon">
                <i :data-feather="sub_item.icon"></i>
              </div>
              <div class="side-menu__title">{{ sub_item.title }}</div>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <!-- END: Side Menu -->
</template>

<script>
import MenuItemsMixins from "@/mixins/MenuItemsMixins";
import { mapGetters } from "vuex";
export default {
  name: "side-menu",
  mixins: [MenuItemsMixins],
  computed: {
    ...mapGetters({ current_user: "auth/user" }),
  },
  methods: {
    isActive(item) {
      return !item.permission
        ? true
        : !!this.current_user.permissions[item.permission];
    },
  },
};
</script>

<style></style>
