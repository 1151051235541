import {
  getFirestore,
  doc,
  collection,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  deleteDoc,
} from "@firebase/firestore";

export const state = {
  orders: [],
  order: {},
  loading: false,
};

export const getters = {
  orders(state) {
    return state.orders;
  },
  order(state) {
    return state.order;
  },
};

export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },
  SET_ORDER(state, order) {
    state.order = order;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async getAll({ commit }) {
    commit("SET_LOADING", true);
    const q = query(collection(getFirestore(), "sav-orders"));
    onSnapshot(q, (querySnapshot) => {
      const orders = querySnapshot.docs.map((order) => ({
        ...order.data(),
        id: order.id,
      }));
      commit("SET_ORDERS", orders);
      commit("SET_LOADING", false);
    });
  },
  async create({ commit }, { orders, order }) {
    commit("SET_LOADING", true);
    const db = getFirestore();
    if (order) {
      const ref = doc(db, "sav-orders", order.code);
      await setDoc(ref, order, { merge: true });
    } else {
      for (let order of orders) {
        const ref = doc(db, "sav-orders", order.code);
        await setDoc(ref, order, { merge: true });
      }
    }
    commit("SET_LOADING", false);
  },
  async get({ commit }, id) {
    commit("SET_LOADING", true);
    const docSnapshot = await getDoc(doc(getFirestore(), "sav-orders", id));
    const order = { ...docSnapshot.data(), id: docSnapshot.id };
    commit("SET_ORDER", order);
    commit("SET_LOADING", false);
    return order;
  },
  async delete({ commit }, id) {
    commit("SET_LOADING", true);
    await deleteDoc(doc(getFirestore(), "sav-orders", id));
    commit("SET_LOADING", false);
  },
};
