import { getFirestore, doc, getDoc, setDoc } from "@firebase/firestore";

export const state = {
  settings: [],
  loading: false,
};

export const getters = {
  settings(state) {
    return state.settings;
  },
};

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async get({ commit }) {
    commit("SET_LOADING", true);
    const settings_data = await getDoc(
      doc(getFirestore(), "sav-system", "specs")
    );
    commit("SET_SETTINGS", { ...settings_data.data(), id: settings_data.id });
    commit("SET_LOADING", false);
    return { ...settings_data.data(), id: settings_data.id };
  },
  async update({ commit }, data) {
    commit("SET_LOADING", true);
    await setDoc(doc(getFirestore(), "sav-system", "specs"), {
      ...data,
    });
    commit("SET_SETTINGS", { ...state.settings, ...data });
    commit("SET_LOADING", false);
  },
};
