import { getFirestore, doc, getDoc, setDoc } from "@firebase/firestore";

import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";

export const state = {
  settings: [],
  loading: false,
};

export const getters = {
  settings(state) {
    return state.settings;
  },
};

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async get({ commit }) {
    commit("SET_LOADING", true);
    const settings_data = await getDoc(
      doc(getFirestore(), "sav-system", "common")
    );
    commit("SET_SETTINGS", { ...settings_data.data(), id: settings_data.id });
    commit("SET_LOADING", false);
    return { ...settings_data.data(), id: settings_data.id };
  },
  async update({ dispatch, commit }, data) {
    commit("SET_LOADING", true);
    let logo = null;
    if (data.logo.file) {
      logo = await dispatch("uploadImage", {
        image: data.logo.file,
      });
    } else {
      logo = data.logo.path;
    }
    await setDoc(doc(getFirestore(), "sav-system", "common"), {
      ...data,
      logo,
    });
    commit("SET_SETTINGS", { ...state.settings, ...data, logo });
    commit("SET_LOADING", false);
  },
  async uploadImage(_ctx, { image }) {
    const imageRef = ref(getStorage(), "sav-system/logo.png");
    await uploadBytes(imageRef, image);
    return await getDownloadURL(imageRef);
  },
};
