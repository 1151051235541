<template>
  <!-- BEGIN: Breadcrumb -->
  <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
    <a href="#">Application</a>
    <i data-feather="chevron-right" class="breadcrumb__icon"></i>
    <a href="#" class="breadcrumb--active">Dashboard</a>
  </div>
  <!-- END: Breadcrumb -->
</template>

<script>
export default {
  name: "BreadcrumbSection",
};
</script>

<style></style>
