import { getFirestore, doc, getDoc, setDoc } from "@firebase/firestore";

import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";

export const state = {
  settings: [],
  loading: false,
};

export const getters = {
  settings(state) {
    return state.settings;
  },
  banners(state) {
    return state.settings.banners;
  },
};

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async get({ commit }) {
    commit("SET_LOADING", true);
    const settings_data = await getDoc(
      doc(getFirestore(), "sav-system", "home-page")
    );
    commit("SET_SETTINGS", { ...settings_data.data(), id: settings_data.id });
    commit("SET_LOADING", false);
    return { ...settings_data.data(), id: settings_data.id };
  },
  async update_banners({ dispatch, commit }, settings) {
    commit("SET_LOADING", true);
    let banners = [];
    let index = 1;
    for (const image of settings.banners) {
      if (image.file) {
        const img = await dispatch("uploadImage", {
          image: image.file,
          index,
        });
        banners.push(img);
      } else {
        banners.push(image.path);
      }
      index++;
    }

    await setDoc(
      doc(getFirestore(), "sav-system", "home-page"),
      { banners },
      { merge: true }
    );
    commit("SET_SETTINGS", { ...state.settings, banners });
    commit("SET_LOADING", false);
  },
  async uploadImage(_ctx, { image, index }) {
    const imageRef = ref(
      getStorage(),
      "sav-system/images/banners/banner-" + index
    );
    await uploadBytes(imageRef, image);
    return await getDownloadURL(imageRef);
  },
};
