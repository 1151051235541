<template>
  <!-- BEGIN: Account Menu -->
  <div class="intro-x dropdown w-8 h-8">
    <div
      class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      role="button"
      aria-expanded="false"
    >
      <img
        alt="Rubick Tailwind HTML Admin Template"
        src="/assets/images/profile-5.jpg"
      />
    </div>
    <div class="dropdown-menu w-56">
      <div
        class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
      >
        <div class="p-4 border-b border-theme-27 dark:border-dark-3">
          <div class="font-medium">
            {{ curent_user.data?.first_name }}
            {{ curent_user.data?.last_name }}
          </div>
          <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
            Administrateur
          </div>
        </div>
        <div class="p-2">
          <!-- <a
            href="#"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i data-feather="user" class="w-4 h-4 mr-2"></i> Mon profil
          </a> -->
          <!-- <a
            href="#"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i data-feather="edit" class="w-4 h-4 mr-2"></i> Add Account
          </a> -->
          <!-- <a
            href="#"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i data-feather="lock" class="w-4 h-4 mr-2"></i> Reset Password
          </a> -->
          <!-- <a
            href="#"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i data-feather="help-circle" class="w-4 h-4 mr-2"></i> Help
          </a> -->
        </div>
        <div class="p-2 border-t border-theme-27 dark:border-dark-3">
          <a
            @click.prevent="logout"
            href="#"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          >
            <i data-feather="toggle-right" class="w-4 h-4 mr-2"></i>
            Se déconnecter
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Account Menu -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  computed: {
    ...mapGetters({ curent_user: "auth/user" }),
  },
};
</script>

<style></style>
