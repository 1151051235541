// import store from "@/store";
import { getAuth, onAuthStateChanged } from "@firebase/auth";

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

async function requireGuest(to, from, next) {
  if (await getCurrentUser()) {
    next("/");
    return;
  } else {
    next();
  }
}

export default requireGuest;
