import {
  getFirestore,
  doc,
  collection,
  getDoc,
  deleteDoc,
  query,
  onSnapshot,
  addDoc,
  serverTimestamp,
  setDoc,
  increment,
  orderBy,
} from "@firebase/firestore";

export const state = {
  conversations: [],
  conversation: {},
  loading: false,
};

export const getters = {
  conversations(state) {
    return state.conversations;
  },
  conversation(state) {
    return state.conversation;
  },
};

export const mutations = {
  SET_CONVERSATIONS(state, conversations) {
    state.conversations = conversations;
  },
  SET_CONVERSATION(state, conversation) {
    state.conversation = conversation;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async getAll({ commit }) {
    commit("SET_LOADING", true);
    const q = query(
      collection(getFirestore(), "sav-conversations"),
      orderBy("last_message_date", "desc")
    );
    onSnapshot(q, (querySnapshot) => {
      const conversations = querySnapshot.docs.map((conversation) => ({
        ...conversation.data(),
        id: conversation.id,
      }));
      commit("SET_CONVERSATIONS", conversations);
      commit("SET_LOADING", false);
    });
  },
  async get({ commit }, conversation_id) {
    commit("SET_LOADING", true);
    const docSnapshot = await getDoc(
      doc(getFirestore(), "sav-conversation", conversation_id)
    );
    const conversation = { ...docSnapshot.data(), id: conversation_id };
    commit("SET_CONVERSATION", conversation);
    commit("SET_LOADING", false);
    return conversation;
  },
  async deleteMessage({ commit }, { message_id, conversation_id }) {
    commit("SET_LOADING", true);
    console.log("deleting message", message_id, conversation_id);
    try {
      await deleteDoc(
        doc(
          getFirestore(),
          "sav-messages",
          conversation_id,
          "messages",
          message_id
        )
      );
    } catch (error) {
      console.log(error);
    }

    commit("SET_LOADING", false);
  },
  async readMessages(_ctx, { conversation_id }) {
    await setDoc(
      doc(getFirestore(), "sav-conversations", conversation_id),
      {
        unreads_admin: 0,
      },
      { merge: true }
    );
  },
  async sendMessage({ commit }, { conversation_id, message }) {
    commit("SET_LOADING", true);
    const date = serverTimestamp();
    const new_message = {
      message,
      sent_at: date,
      sender: "admin",
      seen: false,
    };
    await addDoc(
      collection(getFirestore(), "sav-messages", conversation_id, "messages"),
      new_message
    );
    await setDoc(
      doc(getFirestore(), "sav-conversations", conversation_id),
      {
        last_message_date: date,
        last_message: new_message,
        unreads_user: increment(1),
        unreads_admin: increment(0),
      },
      { merge: true }
    );
    commit("SET_LOADING", false);
  },
};
