<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <router-link to="/" class="flex mr-auto">
        <img
          alt="Rubick Tailwind HTML Admin Template"
          class="w-6"
          src="/assets/images/logo.svg"
        />
      </router-link>
      <a href="javascript:;" id="mobile-menu-toggler">
        <i
          data-feather="bar-chart-2"
          class="w-8 h-8 text-white transform -rotate-90"
        ></i>
      </a>
    </div>
    <ul class="border-t border-theme-29 py-5 hidden">
      <li v-for="(item, index) of menuItems" :key="index">
        <a v-if="item.items?.length" href="javascript:;" class="menu">
          <div class="menu__icon"><i :data-feather="item.icon"></i></div>
          <div class="menu__title">
            {{ item.title }}
            <div class="menu__sub-icon">
              <i data-feather="chevron-down"></i>
            </div>
          </div>
        </a>
        <router-link
          v-else
          :to="item.to"
          class="menu"
          active-class="menu--active"
        >
          <div class="menu__icon">
            <i :data-feather="item.icon"></i>
          </div>
          <div class="menu__title">{{ item.title }}</div>
        </router-link>
        <ul v-if="item.items?.length" style="display: none">
          <li v-for="sub_item of item.items" :key="sub_item.to">
            <router-link
              :to="sub_item.to"
              class="menu"
              active-class="menu--active"
            >
              <div class="menu__icon">
                <i :data-feather="sub_item.icon"></i>
              </div>
              <div class="menu__title">{{ sub_item.title }}</div>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import MenuItemsMixins from "@/mixins/MenuItemsMixins";
export default {
  mixins: [MenuItemsMixins],
  name: "MobileMenu",
};
</script>

<style></style>
