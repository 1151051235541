import { getAuth, onAuthStateChanged } from "@firebase/auth";

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

async function requireAuth(to, from, next) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
      return;
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
}

export default requireAuth;
