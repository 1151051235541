// import {
//   getFirestore,
//   doc,
//   collection,
//   getDoc,
//   setDoc,
//   deleteDoc,
//   query,
//   onSnapshot,
// } from "@firebase/firestore";

// import {
//   getStorage,
//   ref,
//   uploadBytes,
//   getDownloadURL,
// } from "@firebase/storage";

import { StorageProvider } from "@/services/StorageServices";
import Axios from "axios";

export const state = {
  products: [],
  product: {},
  loading: false,
};

export const getters = {
  products(state) {
    return state.products;
  },
  product(state) {
    return state.product;
  },
};

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_PRODUCT(state, product) {
    state.product = product;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async getAll({ commit }) {
    commit("SET_LOADING", true);
    await Axios.get("https://server.sufoservices.com/api/products").then(
      (res) => {
        commit("SET_PRODUCTS", res.data.products);
        commit("SET_LOADING", false);
      }
    );
  },
  async get({ commit }, slug) {
    commit("SET_LOADING", true);
    const product = await Axios.get(
      `https://server.sufoservices.com/api/products/${slug}`
    ).then((res) => {
      return res.data.product;
    });
    commit("SET_PRODUCT", product);
    commit("SET_LOADING", false);
    return product;
  },
  // async delete({ commit }, id) {
  //   commit("SET_LOADING", true);
  //   // await deleteDoc(doc(getFirestore(), "sav-products", id));
  //   commit("SET_LOADING", false);
  // },
  // async create({ dispatch, commit }, product) {
  //   commit("SET_LOADING", true);
  //   const images = [];
  //   const id = Date.now().toString(36).toUpperCase();
  //   for (let image of product.images) {
  //     const item = await dispatch("uploadImage", {
  //       id,
  //       image: image.file,
  //     });
  //     images.push(item);
  //   }
  //   await setDoc(doc(getFirestore(), "sav-products", id), {
  //     ...product,
  //     images,
  //     poster: images[0],
  //   });
  //   commit("SET_LOADING", false);
  // },
  // async update({ dispatch, commit }, product) {
  //   commit("SET_LOADING", true);
  //   const images = [];

  //   for (let image of product.images) {
  //     let item = image.path;
  //     if (image.file != null) {
  //       item = await dispatch("uploadImage", {
  //         id: product.id,
  //         image: image.file,
  //       });
  //     }
  //     images.push(item);
  //   }

  //   await setDoc(doc(getFirestore(), "sav-products", product.id), {
  //     ...product,
  //     images,
  //     poster: images[0],
  //   });
  //   commit("SET_LOADING", false);
  // },
  async uploadImage(_ctx, { image }) {
    const onProgress = (n) => console.log(n + "%");
    const res = await StorageProvider.upload(
      "image",
      "/compress-image",
      image,
      onProgress
    );
    const path = "https://api.sufoservice.com/" + res.data.image.filename;
    return path;
  },
};
