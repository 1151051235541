import requireAuth from "@/guards/auth-guard";
import requireGuest from "@/guards/guest-guard";
import { createRouter, createWebHistory } from "vue-router";

// const ProductsRoute = [
//   {
//     path: "/create-product",
//     name: "CreateProduct",
//     component: () => import("../views/products/create-product.vue"),
//   },
//   {
//     path: "/list-product",
//     name: "ListProduct",
//     component: () => import("../views/products/list-product.vue"),
//   },
//   {
//     path: "/edit-product/:id",
//     name: "EditProduct",
//     component: () => import("../views/products/edit-product.vue"),
//   },
// ];

const ChatsRoute = [
  {
    path: "/chats",
    name: "ChatProducts",
    component: () => import("../views/chats/ChatListProducts.vue"),
  },
  {
    path: "/chats/:productId",
    name: "ChatList",
    component: () => import("../views/chats/ChatView.vue"),
    children: [
      {
        path: "/chats/:productId/:chatId",
        name: "ChatSingle",
        component: () => import("../views/chats/ChatView.vue"),
      },
    ],
  },
];

const OrdersRoute = [
  {
    path: "/create-order",
    name: "CreateOrder",
    component: () => import("../views/orders/create-order.vue"),
  },
  // {
  //   path: "/new-order",
  //   name: "NewOrder",
  //   component: () => import("../views/orders/new-order.vue"),
  // },
  {
    path: "/list-orders",
    name: "ListOrder",
    component: () => import("../views/orders/list-orders.vue"),
  },
  {
    path: "/details-order/:id",
    name: "DetailsOrder",
    component: () => import("../views/orders/order-details.vue"),
  },
];

// const UsersRoute = [
//   {
//     path: "/users",
//     name: "Users",
//     component: () => import("../views/users/list-users.vue"),
//   },
//   {
//     path: "/user-profile/:id",
//     name: "UserProfile",
//     component: () => import("../views/users/user-profile.vue"),
//   },
// ];

// const SettingsRoute = [
//   {
//     path: "/home-settings",
//     name: "HomePageSettings",
//     component: () => import("../views/settings/home-settings.vue"),
//   },
//   {
//     path: "/general-settings",
//     name: "GeneralSettings",
//     component: () => import("../views/settings/general-settings.vue"),
//   },
//   {
//     path: "/contacts-settings",
//     name: "ContactsSettings",
//     component: () => import("../views/settings/contacts-settings.vue"),
//   },
//   {
//     path: "/specs-settings",
//     name: "SpecsSettings",
//     component: () => import("../views/settings/specs-settings.vue"),
//   },
// ];

// const FaqRoute = [
//   {
//     path: "/faqs",
//     name: "CreateFaq",
//     component: () => import("../views/faqs/list-faq.vue"),
//   },
//   {
//     path: "/faqs/:id",
//     name: "ListFaq",
//     component: () => import("../views/faqs/edit-faq.vue"),
//   },
//   {
//     path: "/create-faq",
//     name: "AddFaq",
//     component: () => import("../views/faqs/create-faq.vue"),
//   },
// ];

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/orders/list-orders.vue"),
  },
  // ...ProductsRoute,
  ...OrdersRoute,
  // ...UsersRoute,
  // ...SettingsRoute,
  // ...FaqRoute,
  ...ChatsRoute,
].map((route) => ({
  ...route,
  meta: {
    requiresAuth: true,
  },
}));

routes.push({
  path: "/login",
  name: "Login",
  component: () => import("../views/LoginView.vue"),
  beforeEnter: requireGuest,
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(requireAuth);

export default router;
