import Axios from "axios";

// const base_url = "http://localhost:5000";
// const base_url = "https://api.sufoservice.com";
const base_url = "https://api.sufoservices.com";

class StorageServices {
  upload(type, uploadUrl, file, onProgress) {
    const formData = new FormData();
    formData.append(type, file);

    return Axios.post(base_url + uploadUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        onProgress(percent);
      },
    });
  }
}

export const StorageProvider = new StorageServices();
