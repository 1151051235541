import { getAuth, signOut } from "@firebase/auth";
import { getFirestore, getDoc, doc } from "@firebase/firestore";

export const state = {
  user: {
    loggedIn: false,
    data: null,
    permissions: {},
  },
};

export const getters = {
  user(state) {
    return state.user;
  },
};

export const mutations = {
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_USER(state, data) {
    state.user.data = data;
  },
  SET_USER_PERMISSIONS(state, permissions) {
    state.user.permissions = permissions;
  },
};

export const actions = {
  async fetchUser({ commit, dispatch }, user) {
    commit("SET_LOGGED_IN", user !== null);
    if (user) {
      dispatch("fetchPermissions", user.uid);
      const userDoc = await getDoc(doc(getFirestore(), "sav-users", user.uid));
      commit("SET_USER", {
        id: user.uid,
        ...userDoc.data(),
      });
    } else {
      commit("SET_USER", null);
    }
  },
  async fetchPermissions({ commit }, user_id) {
    const docSnap = await getDoc(
      doc(getFirestore(), "sav-users", user_id, "private", "permissions")
    );
    commit("SET_USER_PERMISSIONS", docSnap.data());
  },
  async logout({ commit }) {
    await signOut(getAuth());
    location.href = "/login";
    commit("SET_LOGGED_IN", false);
    commit("SET_USER", null);
  },
};
