import { createApp } from "vue";
import Vue3Editor from "vue3-editor";
import { initializeApp } from "firebase/app";

import LoadScript from "vue-plugin-load-script";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// import { Cropper } from "vue-advanced-cropper";

import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);
app.component("v-select", vSelect);
app.component("loading-overlay", Loading);

// eslint-disable-next-line no-undef
// app.component("cropper-img", Cropper);

const options = {
  // You can set your default options here
};

const firebaseConfig = {
  apiKey: "AIzaSyCglpdb_Wi3vIIozs22O8Taj8rtVtQlg8Q",
  authDomain: "savservices-3bb32.firebaseapp.com",
  databaseURL: "https://savservices-3bb32.firebaseio.com",
  projectId: "savservices-3bb32",
  storageBucket: "savservices-3bb32.appspot.com",
  messagingSenderId: "942826553760",
  appId: "1:942826553760:web:8eeadff79a94d165ed64c5",
  measurementId: "G-2RLV7DKG85",
};

// Initialize Firebase
initializeApp(firebaseConfig);

// onAuthStateChanged(getAuth(), (user) => {
//   store.dispatch("fetchUser", user);
// });

store.dispatch("products/getAll");
store.dispatch("categories/getAll");
store.dispatch("users/getAll");
store.dispatch("orders/getAll");
store.dispatch("conversations/getAll");
store.dispatch("faqs/getAll");

app
  .use(Vue3Editor)
  .use(LoadScript)
  .use(store)
  .use(router)
  .use(Toast, options)
  .mount("#app");
