import { getFirestore, doc, getDoc, setDoc } from "@firebase/firestore";

export const state = {
  permissions: [],
  user: {},
  loading: false,
};

export const getters = {
  permissions(state) {
    return state.permissions;
  },
};

export const mutations = {
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async get({ commit }, user_id) {
    commit("SET_LOADING", true);
    const docSnapshot = await getDoc(
      doc(getFirestore(), "sav-users", user_id, "private", "permissions")
    );
    const data = { ...docSnapshot.data(), id: docSnapshot.id };
    commit("SET_PERMISSIONS", data);
    commit("SET_LOADING", false);
    return data;
  },
  async set({ commit }, { id, permissions }) {
    commit("SET_LOADING", true);
    await setDoc(
      doc(getFirestore(), "sav-users", id, "private", "permissions"),
      permissions,
      { merge: true }
    );
    const data = { ...permissions, id };
    commit("SET_PERMISSIONS", data);
    commit("SET_LOADING", false);
    return data;
  },
};
