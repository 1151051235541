<template>
  <!-- BEGIN: Search -->
  <div class="intro-x relative mr-3 sm:mr-6">
    <div class="search hidden sm:block">
      <input
        type="text"
        :value="searchText"
        class="search__input form-control border-transparent placeholder-theme-13"
        placeholder="Rechercher..."
        @input="search"
      />
      <i data-feather="search" class="search__icon dark:text-gray-300"></i>
    </div>
    <a class="notification sm:hidden" href="#">
      <i
        data-feather="search"
        class="notification__icon dark:text-gray-300"
      ></i>
    </a>
    <div class="search-result">
      <div class="search-result__content">
        <div
          v-if="filteredMenuItems.length"
          class="search-result__content__title"
        >
          Pages
        </div>
        <div class="mb-5">
          <router-link
            v-for="(page, index) of filteredMenuItems"
            :key="index"
            :to="page.to"
            class="flex items-center mt-2"
          >
            <div
              class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
            >
              <i class="w-4 h-4" :data-feather="page.icon"></i>
            </div>
            <div class="ml-3">{{ page.title }}</div>
          </router-link>
        </div>
        <div v-if="filteredUsers.length" class="search-result__content__title">
          Utilisateurs
        </div>
        <div class="mb-5">
          <router-link
            v-for="user of filteredUsers"
            :key="user.id"
            :to="'/user-profile/' + user.id"
            class="flex items-center mt-2"
          >
            <i class="w-4 h-4" data-feather="user"></i>
            <div class="ml-3">{{ user.customer }}</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              {{ user.email }}
            </div>
          </router-link>
        </div>
        <div
          v-if="filteredProducts.length"
          class="search-result__content__title"
        >
          Articles
        </div>
        <router-link
          v-for="product of filteredProducts"
          :key="product.id"
          :to="'/edit-product/' + product.id"
          class="flex items-center mt-2"
        >
          <div class="w-8 h-8 image-fit">
            <img
              alt="Rubick Tailwind HTML Admin Template"
              class="rounded-full"
              :src="product.poster"
            />
          </div>
          <div class="ml-3">{{ product.name }}</div>
          <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
            {{ product.category }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <!-- END: Search -->
</template>

<script>
import { MenuItems } from "@/mixins/MenuItemsMixins";
export default {
  name: "Search-section",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchText: "",
      menuItems: MenuItems,
    };
  },
  methods: {
    search($event) {
      this.searchText = $event.target.value;
    },
  },
  computed: {
    filteredProducts() {
      return this.products
        .filter((product) => {
          return (
            product.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            product.description
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
        })
        .slice(0, 4);
    },
    filteredUsers() {
      return this.users
        .filter((user) => {
          return (
            user.customer
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            user.email.toLowerCase().includes(this.searchText.toLowerCase())
          );
        })
        .slice(0, 5);
    },
    filteredMenuItems() {
      return this.menuItems
        .filter((item) => {
          return item.title
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        })
        .slice(0, 3);
    },
  },
};
</script>

<style></style>
