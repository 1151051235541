import {
  getFirestore,
  doc,
  collection,
  getDoc,
  addDoc,
  query,
  onSnapshot,
} from "@firebase/firestore";

export const state = {
  categories: [],
  category: {},
  loading: false,
};

export const getters = {
  categories(state) {
    return state.categories;
  },
  category(state) {
    return state.category;
  },
};

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_CATEGORY(state, category) {
    state.category = category;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async getAll({ commit }) {
    commit("SET_LOADING", true);
    const q = query(collection(getFirestore(), "sav-categories"));
    onSnapshot(q, (querySnapshot) => {
      const categories = querySnapshot.docs
        .map((category) => ({
          ...category.data(),
          id: category.id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      commit("SET_CATEGORIES", categories);
      commit("SET_LOADING", false);
    });
  },
  async get({ commit }, id) {
    commit("SET_LOADING", true);
    const category = await getDoc(doc(getFirestore(), "sav-categories", id));
    commit("SET_CATEGORY", { ...category.data(), id: category.id });
    commit("SET_LOADING", false);
  },
  async create({ commit }, category) {
    commit("SET_LOADING", true);
    await addDoc(collection(getFirestore(), "sav-categories"), category);
    commit("SET_LOADING", false);
  },
};
